import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import ALink from "../utils/alink";
import { cartActions } from "../store/cart";
import { modalActions } from "../utils/modal";
import { wishlistActions } from "../store/wishlistReducer";
import { toDecimal } from "../utils";
import { useApiData } from '../../service/api-data-provider';

function ProductTwo(props) {
  const {
    product,
    adClass = "text-center",
    toggleWishlist,
    wishlist,
    addToCart,
    openQuickview,
    collectionSlug = "",
  } = props;
  const { 
    tenantConfiguration, 
    tenantConfigurationLoading, 
    tenantConfigurationIsError,
  } = useApiData();
  if (tenantConfigurationLoading) {
    return '';
  }

  if (tenantConfigurationIsError) {
    return <div>Error loading data</div>;
  }
  return (
    <div className={`product text-left ${adClass}`}>
      <figure className="product-media">
        <ALink
          href={`${
            collectionSlug.length > 0 ? `/collections/${collectionSlug}` : ""
          }/products/${product.slug}`}
        >
          <LazyLoadImage
            alt="product"
            src={product.pictures[0].url}
            threshold={500}
            effect="opacity"
            width="300"
            height="338"
          />

          {product.pictures.length >= 2 ? (
            <LazyLoadImage
              alt="product"
              src={product.pictures[1].url}
              threshold={500}
              width="300"
              height="338"
              effect="opacity"
              wrapperClassName="product-image-hover"
            />
          ) : (
            ""
          )}
        </ALink>

        <div className="product-label-group">
          {product.is_new && (
            <label className="product-label label-new">New</label>
          )}
          {product.is_top && (
            <label className="product-label label-top">Top</label>
          )}
          {product.discount > 0 && product.variants.length === 0 && (
            <label className="product-label label-sale">
              {product.discount}% OFF
            </label>
          )}
          {product.discount > 0 && product.variants.length > 0 && (
            <label className="product-label label-sale">Sale</label>
          )}
        </div>

        <div className="product-action">
          <ALink
            href={
              collectionSlug.length > 0
                ? `/collections/${collectionSlug}/products/${product.slug}`
                : `/products/${product.slug}`
            }
            className="btn-product btn-quickview"
            title="Quick View"
          >
            View
          </ALink>
        </div>
      </figure>

      <div className="product-details">
        <div className="product-cat">
          <ALink>{product.collection.collectionName}</ALink>
        </div>
        <h3 className="product-name">
          <ALink
            href={
              collectionSlug.length > 0
                ? `/collections/${collectionSlug}/products/${product.slug}`
                : `/products/${product.slug}`
            }
          >
            {product.name}
          </ALink>
        </h3>
        <div className="product-name">
          <div className="new-price">
            {tenantConfiguration.REACT_APP_CURRENCY}
            {toDecimal(product.onePrice)}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.data ? state.wishlist.data : [],
  };
}

export default connect(mapStateToProps, {
  toggleWishlist: wishlistActions.toggleWishlist,
  addToCart: cartActions.addToCart,
  ...modalActions,
})(ProductTwo);
