import alasql from "alasql";
import ProductDatabaseService from "./product-database-service";
import {fetchProductDatabase} from '../service/api-data-provider'
export default class DocGenerationService {
    static getCollectionListFromRawData = (rawData) => {
        const structuredData = alasql('SELECT * FROM ? WHERE LENGTH("Checkout Line Item Summary") > 0', [rawData]);
        const collectionNamesSet = new Set();

        structuredData.forEach(record => {
            const lineItemSummary = record['Checkout Line Item Summary'];
            if (lineItemSummary) {
                const formattedLineItems = lineItemSummary.split(/\s*\(\d+\)\s*/).map(item => item.trim()).filter(item => item.length > 0);
                record.lineItems = [];
                formattedLineItems.forEach(item => {
                    try {
                        const parsedItem = JSON.parse(item.replace(/;$/, ''));
                        record.lineItems.push(parsedItem);
                        if (parsedItem.collectionName) {
                            collectionNamesSet.add(parsedItem.collectionName);
                        }
                    } catch (error) {
                        console.error("Failed to parse JSON:", item, error);
                    }
                });
            }
        });

        return Array.from(collectionNamesSet);
    };
    static getStructuredDataForSupplierReportForCollection = async (rawData) => {
        const structuredData = {};
            let collectionData = await fetchProductDatabase();
        for (const record of rawData) {
            const lineItemSummary = record['Checkout Line Item Summary'];
            if (lineItemSummary) {
                const lineItems = lineItemSummary
                    .split(/\s*\(\d+\)\s*/)
                    .map(item => item.trim())
                    .filter(item => item.length > 0);
                for (const item of lineItems) {
                    try {
                        const parsedItem = JSON.parse(item.replace(/;$/, ''));
                        const { collectionName, productName, customisations, collectionId, productId } = parsedItem;
    
                        if (!structuredData[`${collectionName}-${productName}`]) {
                            const imageUrl = this.getImageURL(collectionData, productId, collectionId);
                            structuredData[`${collectionName}-${productName}`] = {
                                collectionName: collectionName,
                                productName: productName,
                                imageUrl: imageUrl,
                                customisation: []
                            };
                        }
                        if (customisations) {
                            structuredData[`${collectionName}-${productName}`].customisation.push(...customisations);
                        }
                    } catch (error) {
                        console.error("Failed to parse line item:", item, error);
                    }
                }
            }
        }
        return Object.values(structuredData);
    };
    static getImageURL = (collectionData, productSlug, collectionSlug) => {
        const collection = collectionData.find(
            (col) => col.collectionSlug === collectionSlug
        );
        if (!collection) {
            return null;
        }
    
        const product = collection.products.find(
            (prod) => prod.slug === productSlug
        );
        return product 
            ? (product.pictures.length > 0 ? product.pictures[0].url : null) 
            : collection.bannerImage;
    };
    static getStructuredDataPDPExcelFile = (rawData) => {
        return rawData.flatMap(order => {
            const lineItems = this.extractLineItems(order['Checkout Line Item Summary']);
            return lineItems.map(orderLineItem => {
                if (orderLineItem.customisations && orderLineItem.customisations.length > 0) {
                    const customisation = orderLineItem.customisations[0];
                    return {
                        orderID: order.id,
                        colI: 372,
                        colJ: 1,
                        colK: 1,
                        colL: "N",
                        colM: "O",
                        colN: "",
                        colO: "",
                        colP: "",
                        colQ: "",
                        colR: "",
                        colS: "",
                        colT: "",
                        colU: "",
                        colV: "",
                        colW: "",
                        phone: order['Customer Phone'],
                        colZ: "",
                        colAA: "",
                        colAB: "",
                        colAC: "7518L3",
                        email: order['Customer Email'],
                        colAE: "Y",
                        colAF: "Y",
                        phoneAG: order['Customer Phone']
                    };
                }
                return null; // Return null if there are no customisations
            }).filter(item => item !== null); // Filter out null entries
        });
    };
    
    static extractLineItems = (lineItemSummary) => {
        const items = lineItemSummary.split(';');
        const lineItems = [];
    
        items.forEach(item => {
            try {
                const parsedItem = JSON.parse(item.split('(')[0].trim());
                lineItems.push(parsedItem);
            } catch (error) {
                console.error('Error parsing line item:', error);
            }
        });
    
        return lineItems;
    };
    
}
