import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ShopBanner from '../banner/shop-banner';
import { useParams } from "react-router-dom";
import Spinner from '../utils/spinner/full-page-spinner';
import ProductDatabaseService from '../../service/product-database-service';
import ProductTwo from './productTwo';
import { useApiData } from '../../service/api-data-provider';

function CollectionView() {
    const { collectionSlug } = useParams();
    const [products, setProducts] = useState([]);
    const [bannerImageUrl, setBannerImageUrl] = useState([]);
    const {
        productDatabase,
        productDatabaseLoading,
        productDatabaseIsError,
    } = useApiData();

    useEffect(() => {
        if (!productDatabaseLoading) {
            getProductAndCollectionData();
        }
    }, [collectionSlug, productDatabaseLoading]);

    const getProductAndCollectionData = () => {
        try {
            const filteredProducts = ProductDatabaseService.getProductsByCollectionSlug(
                collectionSlug,
                productDatabase
            );
            const collectionBannerImage = ProductDatabaseService.getCollectionBannerImage(
                collectionSlug,
                productDatabase
            );
            setBannerImageUrl(collectionBannerImage)
            setProducts(filteredProducts);
        } catch (error) {
            console.error('Error loading products:', error);
            setProducts([]);
        }
    };

    if (productDatabaseLoading) {
        return <Spinner />;
    }

    if (productDatabaseIsError) {
        return <div>Error loading data</div>;
    }

    if (products.length === 0) {
        return <div>No products available for this collection.</div>;
    }
    const { collectionName = '', collectionSlug: slug = ''  } = products[0]?.collection || {};

    return (
        <main className="main">
            <Helmet>
                <title>Collection | {collectionName}</title>
            </Helmet>
            <ShopBanner
                subTitle=""
                title={collectionName}
                current={slug}
                bannerImageUrl={bannerImageUrl}
            />

            <div className="page-content mb-lg-10 mb-0 pb-lg-6 pb-0">
                <div className="container">
                    <div className="row main-content-wrap gutter-lg">
                        <div className="main-content">
                            <div className={`row product-wrapper cols-2 cols-sm-3 cols-md-4 mt-5`}>
                                {products.map(item => (
                                    <div className="product-wrap" key={'shop-' + item.slug}>
                                        <ProductTwo
                                            product={item}
                                            adClass=""
                                            collectionSlug={collectionSlug}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default React.memo(CollectionView);
