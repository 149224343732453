import alasql from "alasql";
export default class ProductDatabaseService {
  static getSingleProductByProductSlug = (productSlug, productDatabase) => {
    const flattenedData = this.getFlatCollectionDatabase(productDatabase);
    const query = `SELECT * FROM ? WHERE LOWER(slug) LIKE LOWER('%' + ? + '%')`;
    let matchProduct = alasql(query, [flattenedData, productSlug]);
    return matchProduct[0];
  };
  static getProductsByCollectionSlug = (collectionSlug, productDatabase) => {
    const queryString = `SELECT * FROM ? WHERE collectionSlug = '${collectionSlug}'`;
    const alasqlResponse = alasql(queryString, [productDatabase, collectionSlug]);
    return alasqlResponse[0].products;
  };
  static getCollectionBannerImage = (collectionSlug, productDatabase) => {
    const queryString = `SELECT CollectionBannerImage FROM ? WHERE collectionSlug = '${collectionSlug}'`;
    const alasqlResponse = alasql(queryString, [productDatabase, collectionSlug]);
    return alasqlResponse[0].CollectionBannerImage;
  };
  static searchAll = (searchKey, productDatabase) => {
    if (searchKey.length > 2 && productDatabase.length > 0) {
      const flattenedData = this.getFlatCollectionDatabase(productDatabase);
      const sql = `SELECT * FROM ? WHERE LOWER(name) LIKE '%${searchKey.toLowerCase()}%'OR LOWER(collectionName) LIKE '%${searchKey.toLowerCase()}%'`;
      return alasql(sql, [flattenedData]) || [];
    }
    return [];
  };
  static getFlatCollectionDatabase = (collectionDatabase) => {
    const flattenedData = collectionDatabase.flatMap((collection) =>
      collection.products.map((product) => ({
        ...product,
        collectionName: collection.collectionName,
        collectionSlug: collection.collectionSlug,
        bannerImage: collection.bannerImage,
      }))
    );
    return flattenedData;
  };
  static getDeliveryOptions = (cartList,productDatabase) => {
    const collectionSlugs = alasql('SELECT DISTINCT collection->collectionSlug AS slug FROM ?', [cartList]).map(item => item.slug);
    if (collectionSlugs.length > 0) {
      const inClause = collectionSlugs.map(slug => `'${slug}'`).join(', ');
      const result = alasql(`SELECT deliveryOptions FROM ? WHERE collectionSlug IN (${inClause})`, [productDatabase]);
      return result.length > 0 ? result[0] : [];
    }
    return [];
  }
}
