import React, { useState, useEffect, memo } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import GenerateInputFields from "../dynamic-form/generate-input-fields";
import MediaOne from "../utils/product-media/MediaOneForGental";
import { useParams } from "react-router-dom";
import Quantity from "../utils/quantity/Quantity";
import { cartActions } from "../store/cart";
import ALink from "../utils/alink";
import { fadeIn } from "../utils/keyFrames";
import Reveal from "react-awesome-reveal";
import Spinner from "../utils/spinner/full-page-spinner";
import ProductDatabaseService from "../../service/product-database-service";

import { useApiData } from '../../service/api-data-provider';
function ProductHome(props) {
  const { productDatabase , productDatabaseLoading ,productDatabaseIsError ,tenantConfiguration, 
    tenantConfigurationLoading, 
    tenantConfigurationIsError,  } = useApiData();
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const { productSlug } = useParams();
  useEffect(() => {
    if(!productDatabaseLoading){
      loadProductDatabase();
    }
  }, [productSlug ,productDatabaseLoading ]);

  const loadProductDatabase = () => {
    let product = ProductDatabaseService.getSingleProductByProductSlug(
      productSlug,productDatabase
    );
    setProduct(product);
  };
  const handleAddToCartSubmit = (event) => {
    event.preventDefault();
    const formElement = document.getElementById("productForm");
    const uniqueProductInstanceId = createUniqueNameForProduct(formElement);
    const formFieldsWithValues = populateFormFieldsWithValues(formElement);

    props.addToCart({
      ...product,
      productInstanceId: uniqueProductInstanceId,
      qty: quantity,
      price: product.onePrice,
      formFields: formFieldsWithValues,
    });
    formElement.reset();
  };

  const populateFormFieldsWithValues = (formElement) => {
    const formData = new FormData(formElement);
    const fieldValues = Object.fromEntries(formData.entries());
    return product.formFields.map((field) => ({
      ...field,
      value: fieldValues[field.name] || "",
    }));
  };

  const createUniqueNameForProduct = (formElement) => {
    const formData = new FormData(formElement);
    return JSON.stringify({
      productName: product.name,
      formDetails: Object.fromEntries(formData.entries()),
    });
  };

  const changeQty = (qty) => setQuantity(qty);

  if (!product) {
    return <p className="container mt-2">Product Not Found</p>;
  }
  if (tenantConfigurationLoading || productDatabaseLoading) {
    return <Spinner />;
  }

  if (tenantConfigurationIsError || productDatabaseIsError) {
    return <div>Error loading data</div>;
  }
  return (
    <>
      <Helmet>
        <title>Product | {product.name}</title>
      </Helmet>
      <div className="page-content mb-10 pb-6">
        <div className="container vertical">
          <div className="product product-single row mb-2">
            {/* Media Section */}
            <div className="col-md-6 mt-4 sticky-sidebar-wrapper">
              {/* Check if product and its pictures are available */}
              {product && product.pictures && product.pictures.length > 0 ? (
                <MediaOne product={[product]} />
              ) : (
                <p>Product images are not available.</p>
              )}
            </div>

            {/* Product Details Section */}
            <div className="col-md-6">
              <nav className="breadcrumb-nav">
                <div className="container pl-1">
                  <ul className="breadcrumb">
                    <li>
                      <ALink href="/">
                        <i className="d-icon-home"></i>
                      </ALink>
                    </li>
                    <li>{product.name}</li>
                  </ul>
                </div>
              </nav>
              <form
                className="pl-lg-2"
                onSubmit={handleAddToCartSubmit}
                id="productForm"
              >
                <main className="main contact-us">
                  <div className="page-content pt-3">
                    <Reveal
                      keyframes={fadeIn}
                      delay="50"
                      duration="1000"
                      triggerOnce
                    >
                      <section className="contact-section">
                        <div className="w-100">
                          <h4 className="ls-m font-weight-bold">
                            {product.name}
                          </h4>
                          <p>{product.short_description}</p>
                          <h5>
                            {tenantConfiguration.REACT_APP_CURRENCY}
                            {product.onePrice}
                          </h5>
                          <GenerateInputFields
                            formFields={product.formFields}
                          />
                        </div>
                      </section>
                    </Reveal>
                  </div>
                </main>
                <div className="product-form product-qty pb-0">
                  <div className="product-form-group">
                    <Quantity
                      qty={quantity}
                      max={product.stock}
                      product={product}
                      onChangeQty={changeQty}
                    />
                    <button
                      type="submit"
                      className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold"
                    >
                      <i className="d-icon-bag"></i>
                      Add to Cart
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.data || [],
  };
}

export default connect(mapStateToProps, {
  addToCart: cartActions.addToCart,
})(memo(ProductHome));

// MediaOne Component (make sure to check for undefined product data)
