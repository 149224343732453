import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
    },
    productContainer: {
        marginBottom: 15,
        width: '100%',
    },
    productImageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    productImage: {
        height: 70,
        width: 70,
        marginRight: 10,
        objectFit: 'contain',
    },
    title: {
        flex: 1,
        textAlign: 'right',
        bottom: 0
    },
    description: {
        marginTop: 5,
        textAlign: 'right',
    },
    table: {
        width: '100%',
        marginTop: 5,
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#e0e0e0',
        padding: 5,
    },
    tableHeaderCell: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        padding: 2,
    },
    tableCell: {
        flex: 1,
        textAlign: 'center',
    },
});

const Product = ({ imageSrc, title, customisation, headers }) => {
    if (!headers) return null;

    const relevantHeaders = headers.filter((header) => {
        return customisation[0].hasOwnProperty(header);
    });

    return (
        <View style={styles.productContainer}>
            <View style={styles.productImageContainer}>
                <Image
                    src={imageSrc}
                    style={styles.productImage}
                    onError={() => console.log('Failed to load image:', imageSrc)}
                />
                <View style={{ flex: 1 }}>
                    <Text style={styles.title}>{title}</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={styles.tableHeader}>
                    {relevantHeaders.map((header, index) => (
                        <Text style={styles.tableHeaderCell} key={index}>{header}</Text>
                    ))}
                </View>
                {customisation.map((item, index) => (
                    <View style={styles.tableRow} key={index}>
                        {relevantHeaders.map((header, index) => (
                            <Text style={styles.tableCell} key={index}>{item[header] || ''}</Text>
                        ))}
                    </View>
                ))}
            </View>
        </View>
    );
};
const SupplierReportPdf = (props) => {
    const { products } = props;
    const [productHeaders, setProductHeaders] = useState({});
    useEffect(() => {
        if (products.length > 0) {
            const newHeaders = products.reduce((acc, product) => {
                const customisationKeys = Object.keys(product.customisation[0]);
                return { ...acc, [product.productName]: customisationKeys };
            }, {});
            setProductHeaders(newHeaders);
        }
    }, [products]);

    return (
        <Document>
            {products.map((product, index) => (
                <Page style={styles.page} key={index}>
                    <Text style={{ fontSize: 16, marginBottom: 10 }}>{product.collectionName}</Text>
                    <View style={styles.productRow}>
                        <Product
                            imageSrc={product.imageUrl}
                            title={product.productName}
                            customisation={product.customisation}
                            headers={productHeaders[product.productName]}
                        />
                    </View>
                </Page>
            ))}
        </Document>
    );
};

export default SupplierReportPdf;