import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/store';

////////////////  Import CSS  ///////////////////////////////////////
import './index.css';
import './public/sass/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-image-lightbox/style.css';
import './public/vendor/riode-fonts/riode-fonts.css';
/////////////////////////////////////////////////////////////////////

////////////////  Import Components  ///////////////////////////////
import Layout from './components/layout';
import Home from './components/home/home';
import ProductHome from './components/product-view/product-home';
import Cart from './components/cart/cart';
import Error404 from './pages/Error404';
import Checkout from './components/checkout/checkout';
import ContactUs from './components/contact-us/contact-us';
import Order from './components/order/order';
import { ApiDataProvider } from './service/api-data-provider';
import DocumentGenerationHome from './pages/supplier-report-document/document-generation-home';
import CollectionAll from './components/collection-view/collection-all'
import ProductGridView from './components/product-view/product-grid-view';
import ProductViewForCollection from './components/product-view/product-view';
/////////////////////////////////////////////////////////////////////

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ApiDataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout children={<Home />} />} />
            <Route path="/pages/cart" element={<Layout children={<Cart />} />} />
            <Route path="/pages/checkout" element={<Layout children={<Checkout />} />} />
            <Route path="/pages/contact-us" element={<Layout children={<ContactUs />} />} />
            <Route path="/pages/doc-generation" element={<Layout children={<DocumentGenerationHome />} />} />
            <Route path="pages/order?/:session_id" element={<Layout children={<Order />} />} />
            <Route path="*" element={<Layout children={<Error404 />} />} />
            <Route path="collections/:collectionSlug" element={<Layout children={<ProductViewForCollection />} />} />
            <Route path="collections/:collection-slug/products/:productSlug" element={<Layout children={<ProductHome />} />} />
            <Route path="collections/all" element={<Layout children={<CollectionAll />} />} />
          </Routes>
        </Router>
      </ApiDataProvider>
    </Provider>
  </QueryClientProvider>
);

reportWebVitals();
