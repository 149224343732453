import React, { useEffect, useState } from 'react';
import ALink from '../utils/alink';
import { toDecimal, getTotalPrice } from '../utils';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Spinner from '../utils/spinner/full-page-spinner';
import axios from 'axios';
import { useApiData } from '../../service/api-data-provider';
import ProductDatabaseService from '../../service/product-database-service';

const Checkout = ({ cartList }) => {
    const { tenantConfiguration, tenantConfigurationLoading, tenantConfigurationIsError,productDatabase, 
        productDatabaseLoading, 
        productDatabaseIsError,  } = useApiData();
    const [pageSpinner, setPageSpinner] = useState(false);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [showAddressFields, setShowAddressFields] = useState(false);

    const loadDeliveryOptions = () => {
        setPageSpinner(true);
        try {
            const deliveryOptionsResponse = ProductDatabaseService.getDeliveryOptions(cartList ,productDatabase);
            setDeliveryOptions(deliveryOptionsResponse.deliveryOptions || []);
        } catch (error) {
            console.error('Error loading delivery options:', error);
        } finally {
            setPageSpinner(false);
        }
    };
    const generateStripeCheckoutURL = async () => {
        try {
            let tenantID = process.env.REACT_APP_TENANT_ID;
            const response = await axios.post(
                tenantConfiguration.REACT_APP_PIPELINE_PROD_URL,
                {
                    pipelineName: tenantConfiguration.REACT_APP_CHECKOUT_PROD_PIPELINE_NAME,
                    pipelineParams: [
                        { name: 'cartItems', value: JSON.stringify(cartList) },
                        { name: 'checkoutFormDetails', value: getFormDetails() },
                        { name: 'originUrl', value: window.location.origin },
                    ],
                },
                {
                    headers: {
                        'x-tenant-id': tenantID,
                    },
                }
            );
            return response.data.checkOutSessionUrL || '';
        } catch (error) {
            console.error('Error generating checkout URL:', error);
            return '';
        }
    };

    const handlePlaceOrderSubmit = async (event) => {
        event.preventDefault();
        setPageSpinner(true);
        const checkoutURL = await generateStripeCheckoutURL();
        if (checkoutURL) {
            window.location.href = checkoutURL;
        }
        setPageSpinner(false);
    };

    const getFormDetails = () => {
        const formElement = document.getElementById('checkOutForm');
        const formData = new FormData(formElement);
        return JSON.stringify(Object.fromEntries(formData.entries()));
    };

    const handleDeliveryOptions = (e) => {
        const selectedOption = deliveryOptions.find(
            (option) => option.type === JSON.parse(e.target.value).type
        );
        setShowAddressFields(selectedOption?.isAddressRequired || false);
    };

    useEffect(() => {
        if (cartList.length > 0 && !productDatabaseLoading) {
            loadDeliveryOptions();
        }
    }, [cartList , productDatabaseLoading]);

    if (pageSpinner) return <Spinner />;
    if (tenantConfigurationLoading || productDatabaseLoading) return <Spinner />;
    if (tenantConfigurationIsError || !tenantConfiguration || productDatabaseIsError) return <p>Error loading tenant configuration.</p>;

    return (
        <>
            <Helmet>
                <title>{tenantConfiguration.REACT_APP_COMPANY_NAME} | Checkout</title>
            </Helmet>
            <div className="container mt-7 page-content pt-2 pb-1 checkout mb-2">
                {cartList.length > 0 ? (
                    <form
                        className="form"
                        onSubmit={handlePlaceOrderSubmit}
                        id="checkOutForm"
                        aria-label="Checkout Form"
                    >
                        <div className="row pl-3">
                            <h3 className="row mb-2 title title-simple text-left text-uppercase">Personal Details</h3>
                            <div className="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
                                {/* Personal Details */}
                                {['First Name', 'Last Name', 'Email', 'Phone'].map((field) => (
                                    <div className="row mb-2" key={field}>
                                        <label htmlFor={field}>{field.replace(/([A-Z])/g, ' $1')} *</label>
                                        <input
                                            className="form-control"
                                            name={field}
                                            id={field}
                                            type={field === 'email' ? 'email' : 'text'}
                                            required
                                        />
                                    </div>
                                ))}
                                {/* Delivery Options */}
                                <h3 className="row mb-2 title title-simple text-left text-uppercase">Delivery Options</h3>
                                <div className="row mb-2">
                                    <label htmlFor="deliveryOption">Delivery Options *</label>
                                    <select
                                        className="form-control"
                                        name="deliveryOption"
                                        id="deliveryOption"
                                        onChange={handleDeliveryOptions}
                                        required
                                        defaultValue=""
                                    >
                                        <option value="" disabled>
                                            Select Delivery Option
                                        </option>
                                        {deliveryOptions.map((option) => (
                                            <option key={option.type} value={JSON.stringify(option)}>
                                                {option.type} ({tenantConfiguration.REACT_APP_CURRENCY}
                                                {toDecimal(option.cost)})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* Address Fields */}
                                {showAddressFields && (
                                    <>
                                        <h3 className="row mb-2 title title-simple text-left text-uppercase">
                                            Address Details
                                        </h3>

                                        {/* Address Line 1 */}
                                        <div className="row mb-2">
                                            <label htmlFor="address1">Address Line 1 *</label>
                                            <input
                                                className="form-control"
                                                name="address1"
                                                id="address1"
                                                type="text"
                                                required
                                                placeholder="Enter Address Line 1"
                                            />
                                        </div>

                                        {/* Address Line 2 */}
                                        <div className="row mb-2">
                                            <label htmlFor="address2">Address Line 2</label>
                                            <input
                                                className="form-control"
                                                name="address2"
                                                id="address2"
                                                type="text"
                                                placeholder="Enter Address Line 2 (Optional)"
                                            />
                                        </div>

                                        {/* City */}
                                        <div className="row mb-2">
                                            <label htmlFor="city">City *</label>
                                            <input
                                                className="form-control"
                                                name="city"
                                                id="city"
                                                type="text"
                                                required
                                                placeholder="Enter City"
                                            />
                                        </div>

                                        {/* State */}
                                        <div className="row mb-2">
                                            <label htmlFor="state">State *</label>
                                            <input
                                                className="form-control"
                                                name="state"
                                                id="state"
                                                type="text"
                                                required
                                                placeholder="Enter State"
                                            />
                                        </div>

                                        {/* Zip Code */}
                                        <div className="row mb-2">
                                            <label htmlFor="zipCode">Zip Code *</label>
                                            <input
                                                className="form-control"
                                                name="zipCode"
                                                id="zipCode"
                                                type="text"
                                                required
                                                placeholder="Enter Zip Code"
                                            />
                                        </div>

                                        {/* Country */}
                                        <div className="row mb-2">
                                            <label htmlFor="country">Country *</label>
                                            <select
                                                className="form-control"
                                                name="country"
                                                id="country"
                                                required
                                            >
                                                <option value="" disabled selected>
                                                    Select a Country
                                                </option>
                                                <option value="IR">Ireland</option>
                                                <option value="US">United States</option>
                                                <option value="IN">India</option>
                                                {/* Add more countries as needed */}
                                            </select>
                                        </div>
                                    </>
                                )}

                            </div>
                            {/* Order Summary */}
                            <aside className="col-lg-5 sticky-sidebar-wrapper">
                                <div className="sticky-sidebar mt-1">
                                    <div className="summary pt-5">
                                        <table>
                                            <tr>
                                                <td>
                                                    <h3 className="title title-simple text-left text-uppercase">Total</h3>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <h3 className="title title-simple">
                                                        {tenantConfiguration.REACT_APP_CURRENCY}
                                                        {toDecimal(getTotalPrice(cartList))}
                                                    </h3>
                                                </td>
                                            </tr>
                                        </table>
                                        <button type="submit" className="btn btn-dark btn-rounded btn-order">
                                            Place Order
                                        </button>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </form>
                ) : (
                    <div className="empty-cart text-center">
                        <p>No item for checkout.</p>
                        <i className="cart-empty d-icon-bag"></i>
                        <p className="return-to-shop mb-0">
                            <ALink className="button wc-backward btn btn-dark btn-md" href="/">
                                Return to shop
                            </ALink>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    cartList: state.cart.data || [],
});

export default connect(mapStateToProps)(Checkout);
