import React, { useState } from 'react'
import Spinner from '../../components/utils/spinner/full-page-spinner'
import Papa from 'papaparse';
import DocGenerationService from '../../service/doc-generation-service';
import SupplierReportPdf from './PDF/supplier-report-pdf';
import { pdf } from '@react-pdf/renderer';
function DocumentGenerationHome() {
    const [isFullPageSpinnerActive, setFullPageSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [rawData, setRawData] = useState(null);
    const [collectionList, setCollectionList] = useState([]);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                complete: (results) => {
                    setError(null);
                    setRawData(results.data);
                    generateCollectionList(results.data);
                },
                header: true,
                skipEmptyLines: true,
                error: (err) => {
                    setError("Error parsing CSV: " + err.message);
                }
            });
        }
    };
    const generateCollectionList = (rawData) => {
        let collectionListResponse = DocGenerationService.getCollectionListFromRawData(rawData);
        if (collectionListResponse.length > 0) {
            setCollectionList(collectionListResponse)
        }
        else {
            setCollectionList([])
        }
    }
    const generateSupplierReport = async (event) => {
        event.preventDefault();
        setFullPageSpinner(true);
        let formatterLineItems = await DocGenerationService.getStructuredDataForSupplierReportForCollection(rawData)
        let pdfBlob = await pdf(<SupplierReportPdf products={formatterLineItems} />).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        window.open(url);
        setFullPageSpinner(false);
        window.location.reload();
    }

    const generatePDPExcelFile = (event) => {
        event.preventDefault();
        let formatterLineItems = DocGenerationService.getStructuredDataPDPExcelFile(rawData)
        downloadJsonAsCsv(formatterLineItems, 'PDP Report.csv')
        window.location.reload();
    }
    
    function downloadJsonAsCsv(jsonData, filename = 'data.csv') {
        if (!Array.isArray(jsonData) || jsonData.length === 0) {
            console.error("Invalid or empty JSON data");
            return;
        }
        const csv = Papa.unparse(jsonData, {
            header: false,
            quotes: false,
            delimiter: ',',
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setFullPageSpinner(false);
    }
    if (isFullPageSpinnerActive) {
        return <Spinner />
    }
    return (
        <>
            {
                isFullPageSpinnerActive ? <Spinner /> : <div className='mb-4 container'>
                    <h1 className='title hide-on-print'>Upload CSV File</h1>
                    <input className='hide-on-print' type="file" accept=".csv" onChange={handleFileChange} />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <div className='mb-4 hide-on-print'></div>
                    {rawData && rawData.length > 0 && (
                        <>
                            <form id="supplierForm" className='hide-on-print' >
                                <select
                                    id='collectionName'
                                    className='form-control'
                                    name='collectionName'
                                    defaultValue=''
                                    required={true}
                                >
                                    <option value='' disabled>
                                        Available Collections.
                                    </option>
                                    {collectionList.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                                <button className="btn btn-primary mt-4 mr-2" type='submit' onClick={generateSupplierReport} data-action="generateSupplierReport">
                                    Generate Supplier Report For Collection
                                </button>

                                <button className="btn btn-primary mt-4 mr-2" data-action="generateOrderReport" onClick={() => { alert('Coming Soon') }}>
                                    Generate Order Report
                                </button>

                                <button className="btn btn-primary mt-4 mr-2" data-action="generateCustomerMetadata" onClick={() => { alert('Coming Soon') }}>
                                    Generate Address Stickers
                                </button>
                                <button className="btn btn-primary mt-4 mr-2" data-action="generatePDPExcelFile" onClick={generatePDPExcelFile}>
                                    Generate DPD Excel file
                                </button>
                            </form>
                        </>
                    )}
                </div>
            }
        </>

    )
}

export default DocumentGenerationHome
